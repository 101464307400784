<template>
  <div class="popup_wrap doc_req_pop" style="width:900px; height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <form
      id="frm_req_pop"
      @submit.prevent
    >
      <div class="popup_cont cod_cont_area">
        <!-- popup_cont -->
        <h1 class="page_title">
          <!-- Letter of Indemnity -->
          {{ $t('msg.ONEX050P190.042') }}
          <span class="position_relative screen-to-pdf-hide">
            <button class="tbl_icon help" @mouseover="showToolTip()" @mouseleave="showToolTip()"></button>
            <div v-show="toolTipActive" class="tooltip_wrap short position_absolute" style="width:400px; left:0; top:28px;"><!-- tooltip_wrap   -->
              <div class="cont"><!-- cont -->
                <p class="title">{{ $t('msg.ONEX050P190.042') }}</p><!-- Letter of Indemnity -->
                <ul class="bul_list_sm t2">
                  <li>
                    {{ $t('msg.ONEX050P190.044') }}
                  </li>
                </ul>
              </div><!-- cont -->
            </div><!-- tooltip_wrap // -->
          </span>
        </h1>
        <div
          class="content_box"
        >
          <!-- content_box -->
          <!-- 국내/해외 문구 삭제 -->
          <!-- <p>
            {{ $t('msg.ONEX050P190.030') }}
          </p> -->

          <h2 class="content_title">{{ $t('msg.ONEX050P190.043') }}</h2><!-- Shipper LOI 요청서 제출 -->
          <table class="tbl_row">
            <colgroup>
              <col style="width:200px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{{ $t('msg.ONEX050P190.035') }}</th><!-- 제출 -->
                <td>
                  <div class="filebox" id="file_att">
                    <a
                      v-if="uploadParam.length === 0"
                      class="button sm gray file"
                      @click.prevent="uploadFilePop()"
                    >
                      {{ $t('msg.ONEX050P190.036') }}
                    </a>
                    <div v-if="uploadParam.length > 0">
                      <span class="tbl_icon attach"></span>
                      <a class="text_link">{{ uploadParam[0].originalName }}</a>
                      <a
                        class="tbl_icon attdel"
                        @click="removeUploadFile()"
                      >X</a>
                    </div>
                  </div>
                </td>
                <td class="border_left_none">
                  <!--<a class="button sm" href="#">제출</a>-->
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="memberDetail.userCtrCd === 'KR'" class="txt_desc mt10" style="background: none;">
            <span class="font_medium">[{{ $t('msg.ONEX050P190.025') }}]</span>
            {{ $t('msg.ONEX050P190.026') }}   <span class="txt_bar"></span> {{ $t('msg.ONEX050P190.027') }} : {{ $t('msg.ONEX050P190.028') }}<br>
          </p>
        </div><!-- content_box // -->

        <div class="mt10 text_right screen-to-pdf-hide">
          <a class="button blue lg mr5" @click="reqSave()">{{ $t('msg.ONEX050P190.035') }}</a>
          <a class="button gray lg" @click="confirmClose()">{{ $t('msg.ONEX050P190.040') }}</a>
        </div>

        <win-layer-pop ref="uploadPop" class="popup_dim">
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="uploadParam"
            :single-file-yn="true"
            @set-dext-file-info="arrangeUploadParam"
            @close="closePopup"
          />
        </win-layer-pop>
        <div v-show="false" id="guide_pop">
          <component
            :is="customComponent"
            v-if="customComponent"
            @close="closePopup"
          />
        </div>
      </div><!-- popup_cont -->
    </form>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import docs from '@/api/rest/trans/docs'
import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import ContainerGuidePop from '@/pages/trans/popup/ContainerGuidePop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'AttorneyFileUploadPop',
  components: {
    rootComputed,
    WinLayerPop,
    DocUploadPop,
    ContainerGuidePop
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          type: ''
        }
      }
    }
  },
  data () {
    return {
      ops: {
        scrollPanel: {
          speed: 300
        },
        bar: {
          showDelay: 500,
          keepShow: true,
          background: '#c1c1c1',
          opacity: 1
        }
      },
      nowDate: '',
      bookingInfo: {
        type: Object,
        default: null
      },
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      agreeYn: 'N',
      picNmh: '',
      // Description of Goods
      description: '',
      // Invoice Model
      invoiceModel: '',
      toolTipActive: false,
      cstInfoList: [],
      cntrList: [],
      selectFunc: null,
      customComponent: null,
      popParams: {},
      uploadParam: [],
      formData: {
        entPicNo: '',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        //3rd Party Shipper
        updtShprCstNm: '',
        reqRsnCont: '',
        cneCstNm: '',
        uploadFileInfos: [],
        blNo: ''
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
    }
  },
  created () {
    const parentInfo = this.parentInfo
    if (this.$ekmtcCommon.isNotEmpty(parentInfo.blNo)) {
      this.init()
    } else {
      const TH = this
      this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), function () {
        TH.$emit('close')
      })
    }
  },
  methods: {
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
        } else {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }

      const arrPromise = [
        commons.getServerTime().then(res => {
          if (res.data !== undefined && res.data !== null) {
            TH.nowDate = res.data.substring(0, 8)
          }
        }),
        commons.getCstInfo().then(res => {
          this.cstInfoList = res
        })
      ]

      arrPromise.push(
        docs.getReqDocBookingBl(parentInfo.blNo, parentInfo.bkgNo, parentInfo.type).then(async res => {
          const result = res.data
          await TH.setBookingInfo(result)
        })
      )

      await Promise.all(arrPromise)
    },
    async setBookingInfo (result) {
      this.bookingInfo = result

      if (this.$ekmtcCommon.isNotEmpty(this.bookingInfo.blNo)) {
        const blNo = this.bookingInfo.blNo
        await docs.getBkgContainerInfo(blNo).then(res => {
          console.log(res)
        })
      }
    },
    async reqSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      formData.uploadFileInfos = this.uploadParam.map((item) => {
        if (item.new !== true) {
          item.fileId = item.asFileId
        }
        return item
      })

      TH.$delete(formData, 'containerList')

      if (formData.uploadFileInfos.length === 0) {
        const attachSelector = document.querySelector('#file_att')
        TH.$ekmtcCommon.showErrorTooltip(attachSelector, TH.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (isOk) {
        if (TH.agreeYn !== 'Y' && TH.delegateYn === 'Y') {
          TH.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050P190.029'))
          return
        }

        let successFlag = true

        formData.vslCd = TH.bookingInfo.vslCd
        formData.voyNo = TH.bookingInfo.voyNo
        formData.polCtrCd = TH.bookingInfo.polCtrCd
        formData.polPortCd = TH.bookingInfo.polPortCd
        formData.podCtrCd = TH.bookingInfo.podCtrCd
        formData.podPortCd = TH.bookingInfo.podPortCd
        formData.shprCstNo = TH.bookingInfo.shprCstNo
        formData.shprCstNm = TH.bookingInfo.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.bookingInfo.bkgNo
        formData.reqCatCd = TH.parentInfo.reqCatCd

        let result = null
        await docs.reqDocSubmit(formData).then(res => {
          if (res.headers.respcode !== 'C0000') {
            successFlag = false
          }

          result = res.data
        }).catch(() => {
          successFlag = false
        }).finally(() => {
          if (successFlag && result !== null) {
            TH.$ekmtcCommon.alertCallback(TH.$t('js.common.008'), function () {
              TH.$emit('selectFunc', result)
              TH.$emit('close')
            })
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
          }
        })
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    closePopup (compNm) {
      const elemGuidPopup = document.querySelector('#guide_pop')
      this.customComponent = null
      this.selectFunc = null

      if (compNm !== 'confirmClose') {
        elemGuidPopup.style.display = 'none'
      } else {
        this.$emit('close')
      }

      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null
    },
    showToolTip () {
      console.log(this.toolTipActive)
      this.toolTipActive = !this.toolTipActive
    },
    async confirmClose () {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX020P130.062'), useConfirmBtn: true })) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
