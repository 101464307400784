var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "form",
        {
          attrs: { id: "frm_req_pop" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "popup_cont cod_cont_area" },
            [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX050P190.042")) + " "),
                _c(
                  "span",
                  { staticClass: "position_relative screen-to-pdf-hide" },
                  [
                    _c("button", {
                      staticClass: "tbl_icon help",
                      on: {
                        mouseover: function ($event) {
                          return _vm.showToolTip()
                        },
                        mouseleave: function ($event) {
                          return _vm.showToolTip()
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.toolTipActive,
                            expression: "toolTipActive",
                          },
                        ],
                        staticClass: "tooltip_wrap short position_absolute",
                        staticStyle: { width: "400px", left: "0", top: "28px" },
                      },
                      [
                        _c("div", { staticClass: "cont" }, [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX050P190.042"))),
                          ]),
                          _c("ul", { staticClass: "bul_list_sm t2" }, [
                            _c("li", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("msg.ONEX050P190.044")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "content_box" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050P190.043"))),
                ]),
                _c("table", { staticClass: "tbl_row" }, [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX050P190.035"))),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "filebox", attrs: { id: "file_att" } },
                          [
                            _vm.uploadParam.length === 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "button sm gray file",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.uploadFilePop()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.ONEX050P190.036")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.uploadParam.length > 0
                              ? _c("div", [
                                  _c("span", {
                                    staticClass: "tbl_icon attach",
                                  }),
                                  _c("a", { staticClass: "text_link" }, [
                                    _vm._v(
                                      _vm._s(_vm.uploadParam[0].originalName)
                                    ),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tbl_icon attdel",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeUploadFile()
                                        },
                                      },
                                    },
                                    [_vm._v("X")]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _c("td", { staticClass: "border_left_none" }),
                    ]),
                  ]),
                ]),
                _vm.memberDetail.userCtrCd === "KR"
                  ? _c(
                      "p",
                      {
                        staticClass: "txt_desc mt10",
                        staticStyle: { background: "none" },
                      },
                      [
                        _c("span", { staticClass: "font_medium" }, [
                          _vm._v(
                            "[" + _vm._s(_vm.$t("msg.ONEX050P190.025")) + "]"
                          ),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX050P190.026")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.ONEX050P190.027")) +
                            " : " +
                            _vm._s(_vm.$t("msg.ONEX050P190.028"))
                        ),
                        _c("br"),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "mt10 text_right screen-to-pdf-hide" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg mr5",
                    on: {
                      click: function ($event) {
                        return _vm.reqSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050P190.035")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "button gray lg",
                    on: {
                      click: function ($event) {
                        return _vm.confirmClose()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX050P190.040")))]
                ),
              ]),
              _c(
                "win-layer-pop",
                { ref: "uploadPop", staticClass: "popup_dim" },
                [
                  _vm.customComponent
                    ? _c(_vm.customComponent, {
                        tag: "component",
                        attrs: {
                          "parent-info": _vm.uploadParam,
                          "single-file-yn": true,
                        },
                        on: {
                          "set-dext-file-info": _vm.arrangeUploadParam,
                          close: _vm.closePopup,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: { id: "guide_pop" },
                },
                [
                  _vm.customComponent
                    ? _c(_vm.customComponent, {
                        tag: "component",
                        on: { close: _vm.closePopup },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "200px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }